import { DROPDOWN_KEYS_WITH_ALL_CHILDREN_IN_URL } from '@react/Filterbar/constants'
import { FilterbarContext } from '@react/Filterbar/FilterbarContext'
import { DropdownItems, SelectedCheckbox, SelectedFilter } from '@react/Filterbar/Filters/FiltersTypes'
import { filterbarPushToDataLayer } from '@react/Filterbar/utils/filterbar-tracking'
import { LAST_ACTIVE_FILTER } from '@react/ListWrapper/constants'
import { useContext, useState } from 'react'

type useDropdownProps = {
  selectedFilter: SelectedFilter
  dropdownKey: string
  dropdownType: 'checkbox' | 'rangeslider'
  disabled: boolean
}

export const useDropdown = ({ selectedFilter, dropdownKey, dropdownType, disabled }: useDropdownProps) => {
  const [selectedItems, setSelectedItems] = useState(selectedFilter?.value || [])
  const [filteredDropdownItems, setFilteredDropdownItems] = useState(null)
  const [modifiedDropdownsItems, setModifiedDropdownsItems] = useState(null)
  const [resetRangeSliderState, setResetRangeSliderState] = useState(false)
  const {
    analyticsState,
    componentName,
    translationsState,
    isFilterbarOpened,
    setHeaderTitle,
    selectedFilterState,
    setSelectedFilterState,
    activeDropdowns,
    setActiveDropdowns,
    onFilterChange,
  } = useContext(FilterbarContext)

  const isActiveDropdown = activeDropdowns?.find(activeDropdown => activeDropdown === dropdownKey)

  const toggleDropdown = () => {
    if (disabled) {
      return
    }

    if (!isActiveDropdown) {
      setActiveDropdowns([dropdownKey])
      sessionStorage.setItem(LAST_ACTIVE_FILTER, JSON.stringify(dropdownKey))
    } else {
      setActiveDropdowns(activeDropdowns.filter(activeDropdown => activeDropdown !== dropdownKey))
    }

    if (dropdownType === 'rangeslider') {
      const selectedFilterValues = selectedFilter?.value
      const selectedFilterSubFilter = selectedFilterState.subFilter || selectedFilter?.subFilter

      setSelectedFilterState({ key: dropdownKey, value: selectedFilterValues, type: dropdownType, subFilter: selectedFilterSubFilter })

      if (isActiveDropdown) {
        onFilterChange({ key: dropdownKey, value: selectedFilterValues, type: dropdownType, subFilter: selectedFilterSubFilter })
        filterbarPushToDataLayer({
          data: { property: dropdownKey, interactionType: 'button' },
          analytics: analyticsState,
          selection: [...(selectedFilterState?.value || []), ...(selectedFilterSubFilter?.value || [])],
        })
      }
    } else {
      setSelectedFilterState({ key: dropdownKey, value: selectedItems, type: dropdownType })
      if (isActiveDropdown) {
        onFilterChange({ key: dropdownKey, value: selectedItems, type: dropdownType })
        filterbarPushToDataLayer({
          data: { property: dropdownKey, interactionType: 'button' },
          analytics: analyticsState,
          selection: selectedFilterState?.value,
        })
      }
    }
  }

  const handleResetButton = () => {
    setResetRangeSliderState(true)
    setSelectedItems([])
    sessionStorage.setItem(LAST_ACTIVE_FILTER, '')
  }

  const handleSelectButton = () => {
    onFilterChange({
      key: dropdownKey,
      value: selectedFilterState.value,
      type: dropdownType,
      ...(dropdownType === 'rangeslider' ? { subFilter: selectedFilterState.subFilter } : {}),
    })

    filterbarPushToDataLayer({
      data: { property: dropdownKey, interactionType: 'button' },
      analytics: analyticsState,
      selection: selectedFilterState?.value,
    })
    setActiveDropdowns([])
  }

  const onInputFilterChange = (key: string, value: string[]) => {
    setFilteredDropdownItems(
      modifiedDropdownsItems?.filter((item: DropdownItems) => item.value?.toLocaleLowerCase().includes(value[0].toLocaleLowerCase())),
    )
  }

  const updateDropdown = (checkbox: SelectedCheckbox) => {
    const updateDropdownItems = checkbox.parent
      ? filteredDropdownItems?.find((item: DropdownItems) => item.key === checkbox.parent)
      : filteredDropdownItems

    if (checkbox.parent) {
      updateDropdownItems?.children.map((item: DropdownItems) => {
        if (item.key === checkbox.key) {
          item.selected = checkbox.selected
        }
        return item
      })
      updateDropdownItems.selected = updateDropdownItems.children.some((child: DropdownItems) => child.selected)
    } else {
      updateDropdownItems.map((item: DropdownItems) => {
        if (item.key === checkbox.key) {
          item.selected = checkbox.selected
        }
        return item
      })
    }
  }

  const getParentSelectedItems = ({ selectedCheckbox, allChildren }: { selectedCheckbox: SelectedCheckbox[]; allChildren: string[] }) => {
    const allChildrenSelected = selectedCheckbox.every(checkbox => checkbox.selected === true)
    return allChildrenSelected
      ? DROPDOWN_KEYS_WITH_ALL_CHILDREN_IN_URL.includes(dropdownKey)
        ? [...(selectedItems || []).filter(item => item !== selectedCheckbox[0].parent), ...allChildren]
        : [...(selectedItems || []).filter(item => !allChildren.includes(item)), selectedCheckbox[0].parent]
      : [
          ...(selectedItems || []).filter(item => item !== selectedCheckbox[0].parent),
          ...selectedCheckbox.filter(checkbox => checkbox.selected === true).map(item => item.key),
        ]
  }

  const getChildSelectedItemsWithParent = ({
    selectedCheckbox,
    parentItem,
    allChildren,
  }: {
    selectedCheckbox: SelectedCheckbox[]
    parentItem: DropdownItems
    allChildren: string[]
  }) => {
    if (selectedCheckbox[0].selected) {
      return selectedItems?.includes(selectedCheckbox[0].key)
        ? [...selectedItems.filter(item => !allChildren.includes(item)), selectedCheckbox[0].parent]
        : [...(selectedItems || []), selectedCheckbox[0].key]
    } else {
      return selectedItems?.includes(parentItem.key)
        ? [...selectedItems.filter(item => item !== parentItem.key), ...allChildren.filter((child: string) => child !== selectedCheckbox[0].key)]
        : [...(selectedItems || []).filter(item => item !== selectedCheckbox[0].key)]
    }
  }

  const getChildSelectedItems = ({
    selectedCheckbox,
    parentItem,
    allChildren,
  }: {
    selectedCheckbox: SelectedCheckbox[]
    parentItem: DropdownItems
    allChildren: string[]
  }) => {
    if (parentItem) {
      return getChildSelectedItemsWithParent({ selectedCheckbox, parentItem, allChildren })
    } else {
      return selectedCheckbox[0].selected
        ? [...(selectedItems || []), selectedCheckbox[0].key]
        : [...(selectedItems || []).filter(item => item !== selectedCheckbox[0].key)]
    }
  }

  const selectedItemHandler = (selectedCheckbox: SelectedCheckbox[]) => {
    const parentItem = filteredDropdownItems?.find((item: DropdownItems) => item.key === selectedCheckbox[0].parent)
    const allChildren = parentItem?.children.map((child: DropdownItems) => child.key)
    const updatedSelectedItems =
      selectedCheckbox.length > 1
        ? getParentSelectedItems({ selectedCheckbox, allChildren })
        : getChildSelectedItems({ selectedCheckbox, parentItem, allChildren })
    selectedCheckbox?.map(checkbox => updateDropdown(checkbox))
    setSelectedItems([...new Set(updatedSelectedItems)])
  }

  return {
    analyticsState,
    componentName,
    filteredDropdownItems,
    isActiveDropdown,
    isFilterbarOpened,
    modifiedDropdownsItems,
    selectedItems,
    translationsState,
    resetRangeSliderState,
    setModifiedDropdownsItems,
    setFilteredDropdownItems,
    setSelectedItems,
    toggleDropdown,
    handleResetButton,
    handleSelectButton,
    onInputFilterChange,
    selectedItemHandler,
    setHeaderTitle,
    setSelectedFilterState,
    setResetRangeSliderState,
  }
}
