import { ItemType, TranslationsType } from '../FilterableListTypes'

export const getAssetSearchTableHeaders = (translations: TranslationsType) => ({
  items: [
    { value: translations.previewColumn },
    { value: translations.titleColumn },
    { value: translations.descriptionColumn },
    { value: translations.sizeColumn },
    { value: translations.actionsColumn },
  ],
})

export const getAssetShareTableRows = (items: ItemType[]) =>
  items?.map(item => [
    { image: item.image, link: item.url, imageClasses: item?.objectFit ? 'cmp-filterable-list__card-image--no-object-fit' : '' },
    { value: item.title, link: item.url },
    { value: item.description },
    { value: item.size },
    { customTemplate: item },
  ])

export const isAssetInCart = (item: ItemType): boolean => {
  const authorizableId = JSON.parse(sessionStorage.getItem('asset-share-commons'))?.['asset-share-authorizableId']
  const asset_details = JSON.parse(localStorage.getItem('asset-share-commons'))?.[authorizableId]
  const assetsInCart = asset_details?.cart?.assets || []

  return assetsInCart.includes(item.assetShareUrl)
}
