import { useContext, useEffect, useState } from 'react'

import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { FragmentsListTableRows } from '../Components/FragmentsList/FragmentsListTableRows'
import { FilterableListContext } from '../FilterableListContext'
import { Table } from '../UI/Table'
import { getTableHeaders, sortHeaders } from '../utils/table'

export const FragmentsList = () => {
  const { propsState, responseData } = useContext(FilterableListContext)
  const { dynamicFields, items } = responseData
  const { loadMore, numberofrows } = propsState
  const [sortedItems, setSortedItems] = useState(items)
  const [displayedNumberOfItems, setDisplayedNumberOfItems] = useState(+numberofrows)

  const showLoadMore = loadMore === 'true' && displayedNumberOfItems < items.length

  useEffect(() => {
    setSortedItems(items)
    setDisplayedNumberOfItems(+numberofrows)
  }, [items])

  const handleOnLoadMore = () => {
    setDisplayedNumberOfItems(prev => prev + +numberofrows)
  }

  const handleOnCLickHeaders = (column: string, order: 'ASC' | 'DESC') => {
    setSortedItems([
      ...sortHeaders({ items, column: items.find(item => column in item) ? column : column === 'title' ? 'fileName' : 'title', order }),
    ])
  }

  return (
    <div className="cmp-filterable-list">
      <div className="cmp-filterable-list__table-wrapper">
        <Table
          componentName={propsState.componentName}
          handleOnCLickHeaders={handleOnCLickHeaders}
          tableHeaders={getTableHeaders(dynamicFields)}
          tableRowsTemplate={
            <FragmentsListTableRows
              items={sortedItems}
              dynamicFields={dynamicFields}
              showNumberOfRows={displayedNumberOfItems}
              componentName={propsState.componentName}
            />
          }
        />
      </div>
      {showLoadMore && <ButtonsWrapper onLoadMoreHandler={handleOnLoadMore} />}
    </div>
  )
}
