import { cls } from '@react/utils/classname'

import { DynamicFieldType, ItemType } from '../../FilterableListTypes'
import { FragmentsListTableData } from './FragmentsListTableData'

type FragmentsListTableRowsPropsType = {
  items: ItemType[]
  dynamicFields: DynamicFieldType[]
  showNumberOfRows: number
  componentName: string
}

export const FragmentsListTableRows = ({ items, dynamicFields, showNumberOfRows, componentName }: FragmentsListTableRowsPropsType) => {
  return items?.map((item, index) => {
    if (item.link || item.advisoryId) {
      return (
        <tr
          key={index}
          className={cls({
            'cmp-filterable-list__table-row': true,
            'cmp-filterable-list__table--hidden-row': index + 1 > showNumberOfRows,
          })}
        >
          {dynamicFields?.map((field, fieldIndex) => (
            <td key={fieldIndex} className="cmp-filterable-list__table-td">
              <FragmentsListTableData dynamicField={field} item={item} componentName={componentName} />
            </td>
          ))}
        </tr>
      )
    }

    return <></>
  })
}
