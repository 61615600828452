import { TableRowsType } from '@react/FilterableList/UI/Table'
import ImageWrapper from '@react/ImageWrapper'
import { useEffect, useState } from 'react'

export const TableCell = ({ data }: { data: TableRowsType }) => {
  const [imgSrc, setImgSrc] = useState(data.image?.pattern)

  useEffect(() => {
    if (data.image) {
      setImgSrc(data.image.pattern)
    }
  }, [data?.image?.pattern])
  return (
    <>
      {data.image && (
        <ImageWrapper
          modelJson={JSON.stringify(data.image)}
          classes={`cmp-filterable-list__card-image cmp-responsive-image cmp-responsive-image--lazy ${data?.imageClasses}`}
          imageSrc={imgSrc}
        />
      )}
      {!data.image && (data.value || '-')}
    </>
  )
}
