import './Jobs.scss'

import { useContext } from 'react'

import { ButtonsWrapper } from '../Components/ButtonsWrapper'
import { FilterableListContext } from '../FilterableListContext'
import { Table } from '../UI/Table'
import { jobsTableHeaders, jobsTableRows } from '../utils/jobs'

export const Jobs = () => {
  const { responseData, translationsState, propsState } = useContext(FilterableListContext)
  const { filterFields, items } = responseData

  return (
    <div className="cmp-filterable-list cmp-filterable-list__table-wrapper">
      <Table
        classNames={'cmp-filterable-list__jobs'}
        tableHeaders={jobsTableHeaders(translationsState, filterFields)}
        tableRows={jobsTableRows(items)}
        componentName={propsState.componentName}
      />
      <ButtonsWrapper />
    </div>
  )
}
